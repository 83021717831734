<template>
    <el-container>
        <!-- 顶部 -->
        <el-header>
            <Header />
        </el-header>
        <el-container>
            <!-- 左侧边栏 -->
            <el-aside width="220px">
                <div :class="isCollapse ? 'collapse-btn2' : 'collapse-btn'" @click="collapseChage">
                  <i style="font-size: 13px;font-weight: bold;margin-right: 10px;font-style: normal;" v-if="!isCollapse">&lt;&lt; 折叠</i>
                  <i style="font-size: 13px;font-weight: bold;margin-right: 0px;font-style: normal;" v-else>展开 &gt;&gt;</i>
                </div>
                <el-menu :default-active="activePath" class="el-menu-vertical-demo" :collapse="isCollapse" background-color="#e2e2e2" text-color="#000" active-text-color="#1B63C1" @select="handleSelect" unique-opened router>
                    <div v-for="(item,index) in routeName" :key="index">
                        <el-submenu :index="index+''">
                            <template slot="title">
                                <i :class="'iconfont '+item.iconfont"></i>
                                <span slot="title" v-if="!isCollapse"> {{item.meta.title}}</span>
                            </template>
                            <div v-for="(it,id) in item.children" :key='id'>
                                <el-menu-item :index="'/'+it.component" @click="saveNavState('/'+it.component)">{{it.meta.title}}</el-menu-item>
                            </div>
                        </el-submenu>
                    </div>
                </el-menu>
            </el-aside>
            <!-- 中间内容 -->
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Header from "@/components/Header";
export default {
    data() {
        return {
            // 医院用户还是科室用户
            isDoctore: "",
            // 存储左菜单栏的路径
            activePath: "",
            // 左侧边栏
            isCollapse: false,
            one: true, //医院基础数据
            two: true, //科室基础数据
            three: true, //科室标化工作量
            four: true, //科室难度系数监测
            five: true, //绩效测算与模拟
            six: true, //驱动型绩效大智慧
            routeName: "",
        };
    },
    components: {
        Header,
    },
    // 通过监听器解决  导航菜单在页面刷新时会回到默认项
    watch: {
        $route(to) {
            // 监听路由的变化  如果路由发生改变则当前tab栏默认值也相应改变
            // console.log(to.path);
            this.activePath = to.path;
            this.getRouters();
        },
    },
    created() {
        // 当前点击进来的左菜单栏显示
        // console.log(this.$route.meta.activeMenu);
        this.activePath = this.$route.meta.activeMenu;
        this.winty();
        this.getRouters();
        // console.log(JSON.parse(window.localStorage.getItem("routeName")));
    },
    methods: {
        async getRouters() {
            let res = await this.$axios.getRouters();
            this.$store.commit("saveRoute", JSON.stringify(res.data.data));
            this.routeName = JSON.parse(
                window.localStorage.getItem("routeName")
            );
        },
        winty() {
            if (window.sessionStorage.getItem("type") == 0) {
                this.administrators = "医院";
                this.isDoctore = true;
            } else {
                this.administrators = "科室";
                this.isDoctore = false;
            }
        },
        // 左菜单栏点击跳转的页面路径
        handleSelect(key) {
            // console.log(key)
            this.$router.push(key);
        },
        // 侧边栏折叠
        collapseChage() {
            this.isCollapse = !this.isCollapse;
        },
        // 存储点击左菜单栏的路径
        saveNavState(activePath) {
            // console.log(activePath);
            window.sessionStorage.setItem("activePath", activePath);
            this.activePath = activePath;
        },
    },
};
</script>
<style lang="less" scoped>
.el-container.is-vertical {
    height: 100%;
}
.el-menu--collapse {
    height: 100%;
}
.el-menu-vertical-demo {
    text-align: left;
    .iconfont {
        margin-right: 8px;
    }
}
/deep/ .el-submenu__icon-arrow {
    position: absolute;
    top: 54%;
    right: 10px;
    margin-top: -7px;
    transition: transform 0.3s;
    font-size: 12px;
}

.collapse-btn,
.collapse-btn2 {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    background: #f1f1f1;
    text-align: right;
}
.collapse-btn2 {
    width: 64px;
}
/deep/ .el-radio-button__inner {
    width: 100px;
}

.el-aside {
    overflow: hidden;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 220px;
}

/deep/ .el-header {
    position: relative;
    width: 100%;
    height: 80px !important;
    display: flex;
    align-items: center;
    background: #1b63c1;
    color: white;
    justify-content: space-between;
}

/deep/ .el-submenu__title,
/deep/ .el-menu-item {
    font-size: 13px;
}

/deep/ .el-menu-item {
    background: #ccc !important;
}

// 鼠标移入父级的颜色
/deep/ .el-submenu__title:hover {
    background-color: transparent !important;
}

/deep/ .el-menu,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    height: 100%;
}
</style>
