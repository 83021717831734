// 模块接口文件，暴漏给外部模块使用

// 导入核心模块
import { request } from "./core";
// 导入请求方式
import METHODS from "./methods";
// 导入请求接口
import URL from "./url";

const API = {
    // 登录接口
    login(data) {
        return request(METHODS.POST, URL.login, data);
    },
    // 修改密码
    updatePwd(data) {
        return request(METHODS.POST, URL.updatePwd, data);
    },
    // 初次登录修改密码
    updateFirstPwd(data) {
        return request(METHODS.POST, URL.updateFirstPwd, data);
    },
    // 发送验证码
    sms(data) {
        return request(METHODS.POST, URL.sms, data);
    },
    // 退出登录
    forceLogout(data) {
        return request(METHODS.POST, URL.forceLogout, data);
    },
    // 查询科室列表 分页
    getDepartmentList(data) {
        return request(METHODS.GET, URL.getDepartmentList, { params: data });
    },
    // 科室数据更新时间列表
    departmentList(data) {
        return request(METHODS.GET, URL.departmentList, { params: data });
    },
    // 获取科室用户列表 分页
    getDepartmentUserList(data) {
        return request(METHODS.GET, URL.getDepartmentUserList, { params: data });
    },
    // 添加或修改科室用户
    addAndEdit(data) {
        return request(METHODS.POST, URL.addAndEdit, data);
    },
    // 添加或修改科室信息
    addDepartment(data) {
        return request(METHODS.POST, URL.addDepartment, data);
    },
    // 删除科室用户
    removeDepartmentUser(data) {
        return request(METHODS.POST, URL.removeDepartmentUser, data);
    },
    // 删除科室
    removeDepartment(data) {
        return request(METHODS.POST, URL.removeDepartment, data);
    },
    // 科室服务项目列表 分页
    getBusinessList(data) {
        return request(METHODS.GET, URL.getBusinessList, { params: data });
    },
    // 添加或修改项目
    editDepartmentBusiness(data) {
        return request(METHODS.POST, URL.editDepartmentBusiness, data);
    },
    // 医院年份列表 分页
    getHospitalBusinessList(data) {
        return request(METHODS.GET, URL.getHospitalBusinessList, { params: data });
    },
    // 添加医院年份信息
    addHospitalBusiness(data) {
        return request(METHODS.POST, URL.addHospitalBusiness, data);
    },
    // 修改医院 薪资
    updateHospitalSalary(data) {
        return request(METHODS.POST, URL.updateHospitalSalary, data);
    },
    // 获取薪资信息
    getHospitalBusiness(data) {
        return request(METHODS.GET, URL.getHospitalBusiness, { params: data });
    },
    // 获取医院所属科室列表
    getDepartmentNotPage(data) {
        return request(METHODS.GET, URL.getDepartmentNotPage, { params: data });
    },
    // 查询科室年份列表 分页
    getDepartmentYearList(data) {
        return request(METHODS.GET, URL.getDepartmentYearList, { params: data });
    },
    //查询科室年份详情列表 分页
    getDepartmentYearDetailsList(data) {
        return request(METHODS.GET, URL.getDepartmentYearDetailsList, { params: data });
    },
    //科室月份工作量数据 分页
    getDepartmentPriceRatioList(data) {
        return request(METHODS.GET, URL.getDepartmentPriceRatioList, { params: data });
    },
    // 修改单条填报数据
    updateDepartmentPriceRatio(data) {
        return request(METHODS.POST, URL.updateDepartmentPriceRatio, data);
    },
    // 计算项目难度值
    coefficientOfDifficulty(data) {
        return request(METHODS.POST, URL.coefficientOfDifficulty, data);
    },
    // 获取科室年度数据 分页
    getDepartmentYearDataList(data) {
        return request(METHODS.GET, URL.getDepartmentYearDataList, { params: data });
    },
    // 年度数据获取 基期难度系数测算
    getDiffValue(data) {
        return request(METHODS.POST, URL.getDiffValue, data);
    },
    // 科室监控图表
    departmentMonitorChart(data) {
        return request(METHODS.POST, URL.departmentMonitorChart, data);
    },
    // 删除科室填报监测数据
    removeMonitorChart(data) {
        return request(METHODS.POST, URL.removeMonitorChart, data);
    },
    // 添加科室填报监测数据
    editDepartmentMonitor(data) {
        return request(METHODS.POST, URL.editDepartmentMonitor, data);
    },
    // 修改项目是否是 基准项目
    updateBusinessBase(data) {
        return request(METHODS.POST, URL.updateBusinessBase, data);
    },
    // 科室监控列表
    getDepartmentMonitorList(data) {
        return request(METHODS.GET, URL.getDepartmentMonitorList, { params: data });
    },
    // 科室薪酬数据列表 分页
    getDepartmentSalaryList(data) {
        return request(METHODS.GET, URL.getDepartmentSalaryList, { params: data });
    },
    // 修改科室薪资月份表
    updateDepartmentSalaryDetails(data) {
        return request(METHODS.POST, URL.updateDepartmentSalaryDetails, data);
    },
    // 科室薪资月份列表
    getDepartmentSalaryDetails(data) {
        return request(METHODS.GET, URL.getDepartmentSalaryDetails, { params: data });
    },
    // 科室标化工作总量月份数据 分页
    getDepartmentPriceRatioStandList(data) {
        return request(METHODS.GET, URL.getDepartmentPriceRatioStandList, { params: data });
    },
    // 2022-4-19  新接口 创建测算模拟
    createSimulation(data) {
        return request(METHODS.POST, URL.createSimulation, data);
    },
    // 科室模拟测算列表
    getSimulationList(data) {
        return request(METHODS.GET, URL.getSimulationList, { params: data });
    },
    // 2022-4-19 新接口  模拟测算（进行计算数据）
    calculateData(data) {
        return request(METHODS.POST, URL.calculateData, data);
    },
    // 删除模拟测算
    removeSimulation(data) {
        return request(METHODS.POST, URL.removeSimulation, data);
    },
    // 导出模拟测算
    importSimulationExcel(data) {
        return request(METHODS.GET, URL.importSimulationExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 获取单个模拟测算数据
    getSimulationCalculate(data) {
        return request(METHODS.GET, URL.getSimulationCalculate, { params: data });
    },
    // 获取医院所有年份 不分页
    getHospitalYear(data) {
        return request(METHODS.GET, URL.getHospitalYear, { params: data });
    },
    // 模拟测算中获取科室下各个年度月份工作量填报信息
    getYearAndMonth(data) {
        return request(METHODS.GET, URL.getYearAndMonth, { params: data });
    },
    // 进入医疗服务项目页面先查询 所用计算的年份
    getHospitalYearCalculation(data) {
        return request(METHODS.GET, URL.getHospitalYearCalculation, { params: data });
    },
    // 根据年份计算标化价值
    calculationPriceRatio(data) {
        return request(METHODS.POST, URL.calculationPriceRatio, data);
    },
    // 医院数据更新时间列表
    hospitalList(data) {
        return request(METHODS.GET, URL.hospitalList, { params: data });
    },
    // 医院 机构、人员、床位及工作量 添加
    saveHospitalBaseOne(data) {
        return request(METHODS.POST, URL.saveHospitalBaseOne, data);
    },
    // 医院 固定资产 添加
    saveHospitalBaseTwo(data) {
        return request(METHODS.POST, URL.saveHospitalBaseTwo, data);
    },
    // 医院 收入 添加
    saveHospitalBaseThree(data) {
        return request(METHODS.POST, URL.saveHospitalBaseThree, data);
    },
    // 医院 支出 添加
    saveHospitalBaseFour(data) {
        return request(METHODS.POST, URL.saveHospitalBaseFour, data);
    },
    // 医院 负债 添加
    saveHospitalBaseFive(data) {
        return request(METHODS.POST, URL.saveHospitalBaseFive, data);
    },
    // 医院 医疗应收款 添加
    saveHospitalBaseSix(data) {
        return request(METHODS.POST, URL.saveHospitalBaseSix, data);
    },
    // 医院 效率、效益及装备 添加
    saveIndexOne(data) {
        return request(METHODS.POST, URL.saveIndexOne, data);
    },
    // 医院 负债及偿债能力 添加
    saveIndexTwo(data) {
        return request(METHODS.POST, URL.saveIndexTwo, data);
    },
    // 医院 药品 添加
    saveIndexThree(data) {
        return request(METHODS.POST, URL.saveIndexThree, data);
    },
    // 医院 次均费用及财政补偿 添加
    saveIndexFour(data) {
        return request(METHODS.POST, URL.saveIndexFour, data);
    },
    // 医院 支出分析 添加
    saveIndexFive(data) {
        return request(METHODS.POST, URL.saveIndexFive, data);
    },
    // 科室 人员架构 添加
    saveDepartmentBaseOne(data) {
        return request(METHODS.POST, URL.saveDepartmentBaseOne, data);
    },
    // 科室 科室结构 添加
    saveDepartmentBaseTwo(data) {
        return request(METHODS.POST, URL.saveDepartmentBaseTwo, data);
    },
    // 科室 运营效率 添加
    saveDepartmentBaseThree(data) {
        return request(METHODS.POST, URL.saveDepartmentBaseThree, data);
    },
    // 科室 薪酬水平 添加
    saveDepartmentBaseFour(data) {
        return request(METHODS.POST, URL.saveDepartmentBaseFour, data);
    },
    // 数据回显
    getBaseDetails(data) {
        return request(METHODS.GET, URL.getBaseDetails, { params: data });
    },
    // 导出医疗服务项目excel模板
    downBusinessExcel(data) {
        return request(METHODS.GET, URL.downBusinessExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 同步医疗服务项目
    synProject(data) {
        return request(METHODS.GET, URL.synProject, { params: data, responseType: 'arraybuffer' });
    },
    // 同步医疗服务项目
    synYJproject(data) {
        return request(METHODS.GET, URL.synYJproject, { params: data, responseType: 'arraybuffer' });
    },
    // 导入医疗服务项目excel
    importBusinessExcel(data) {
        return request(METHODS.POST, URL.importBusinessExcel, data);
    },
    // 导出科室年份下所有月数据
    exportYearExcel(data) {
        return request(METHODS.GET, URL.exportYearExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 导出科室工作量单月数据
    exportMonthExcel(data) {
        return request(METHODS.GET, URL.exportMonthExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 导出工作量单月数据填报
    exportPriceRatioMonthDetails(data) {
        return request(METHODS.GET, URL.exportPriceRatioMonthDetails, { params: data, responseType: 'arraybuffer' });
    },
    // 导入科室工作量填报数据
    importPriceRatioExcel(data) {
        return request(METHODS.POST, URL.importPriceRatioExcel, data);
    },
    // 重新计算标化工作量
    updateBaseClassByStandWork(data) {
        return request(METHODS.POST, URL.updateBaseClassByStandWork, data);
    },
    // 基准项目
    standardProjectPriceRatio(data) {
        return request(METHODS.POST, URL.standardProjectPriceRatio, data);
    },
    // 通过获取参考当量的数据计算出对应的参考当量系数
    findEquivalent(data) {
        return request(METHODS.POST, URL.findEquivalent, data);
    },
    // 结构调整策略列表
    strList(data) {
        return request(METHODS.GET, URL.strList, { params: data });
    },
    // 新增结构调整策略
    strAdd(data) {
        return request(METHODS.POST, URL.strAdd, data);
    },
    // 结构调整策略详情列表
    strAdjList(data) {
        return request(METHODS.GET, URL.strAdjList, { params: data });
    },
    // 更改项目属性计算调节系数
    updateItemProperties(data) {
        return request(METHODS.POST, URL.updateItemProperties, data);
    },
    // 配置结构调整策略
    configureStrategy(data) {
        return request(METHODS.POST, URL.configureStrategy, data);
    },
    // 删除结构调整策略
    deleteList(data) {
        return request(METHODS.POST, URL.deleteList, data);
    },
    // 更改调节系数
    updateAdjustmentCoefficient(data) {
        return request(METHODS.POST, URL.updateAdjustmentCoefficient, data);
    },
    // 病区列表（带分页）
    listhosInward(data) {
        return request(METHODS.GET, URL.listhosInward, { params: data });
    },
    // 新增病区信息
    addInward(data) {
        return request(METHODS.POST, URL.addInward, data);
    },
    // 编辑修改病区信息
    editTnward(data) {
        return request(METHODS.POST, URL.editTnward, data);
    },
    // 删除病区信息
    deleteTnward(data) {
        return request(METHODS.POST, URL.deleteTnward, data);
    },
    // 病区列表（无分页）
    findByhosInward(data) {
        return request(METHODS.GET, URL.findByhosInward, { params: data });
    },
    // 根据字典类型查询字典数据中的信息
    findBySys(data) {
        return request(METHODS.GET, URL.findBySys, { params: data });
    },
    // 新增科室人员信息
    addhosDepartmentUser(data) {
        return request(METHODS.POST, URL.addhosDepartmentUser, data);
    },
    // 科室人员信息列表(带分页)
    listhosDepartmentUser(data) {
        return request(METHODS.GET, URL.listhosDepartmentUser, { params: data });
    },
    // 文件上传
    hosUpload(data) {
        return request(METHODS.POST, URL.hosUpload, data);
    },
    // 查看图片
    sysgetFile(data) {
        return request(METHODS.POST, URL.sysgetFile, data);
    },
    // 新增学科
    addhosdpl(data) {
        return request(METHODS.POST, URL.addhosdpl, data);
    },
    // 学科管理列表
    listhosdpl(data) {
        return request(METHODS.GET, URL.listhosdpl, { params: data });
    },
    // 编辑修改学科
    updatehosdpl(data) {
        return request(METHODS.POST, URL.updatehosdpl, data);
    },
    // 删除学科
    delehosdpl(data) {
        return request(METHODS.POST, URL.delehosdpl, data);
    },
    // 新增岗位职责
    hospostlistAdd(data) {
        return request(METHODS.POST, URL.hospostlistAdd, data);
    },
    //岗位职责列表 (带分页)
    hospostList(data) {
        return request(METHODS.GET, URL.hospostList, { params: data });
    },
    // 删除岗位职责
    hospostDelete(data) {
        return request(METHODS.POST, URL.hospostDelete, data);
    },
    // 修改岗位职责
    hospostUpdate(data) {
        return request(METHODS.POST, URL.hospostUpdate, data);
    },
    // 新增科室基础信息
    hosdepAdd(data) {
        return request(METHODS.POST, URL.hosdepAdd, data);
    },
    // 编辑修改科室基础信息
    hosdepUpdate(data) {
        return request(METHODS.POST, URL.hosdepUpdate, data);
    },
    // 通过科室id获取科室基础信息
    findDetail(data) {
        return request(METHODS.GET, URL.findDetail, { params: data });
    },
    // 获取科室基础信息
    hoddepFind(data) {
        return request(METHODS.GET, URL.hoddepFind, { params: data });
    },
    // 科室基础信息分页列表
    hoddepList(data) {
        return request(METHODS.GET, URL.hoddepList, { params: data });
    },
    //岗位职责列表 (无分页)
    hosPostRe(data) {
        return request(METHODS.GET, URL.hosPostRe, { params: data });
    },
    //学科管理列表 (无分页)
    hosDisciplines(data) {
        return request(METHODS.GET, URL.hosDisciplines, { params: data });
    },
    //根据医院id查询科室列表 (无分页)
    findDepartment(data) {
        return request(METHODS.GET, URL.findDepartment, { params: data });
    },
    // 删除科室人员信息
    deleteDepartment(data) {
        return request(METHODS.POST, URL.deleteDepartment, data);
    },
    // 编辑修改科室人员信息
    updaDepartment(data) {
        return request(METHODS.POST, URL.updaDepartment, data);
    },
    //获取科室人员详细信息
    hosfindByKey(data) {
        return request(METHODS.GET, URL.hosfindByKey, { params: data });
    },
    // 判断学科是否已存在
    judgeHosDiscipline(data) {
        return request(METHODS.POST, URL.judgeHosDiscipline, data);
    },
    // 判断科室下的病区是否已存在
    judgeInpatientWard(data) {
        return request(METHODS.POST, URL.judgeInpatientWard, data);
    },
    // 判断科室人员的工号是否已存在
    judgeHosDepartmentUser(data) {
        return request(METHODS.POST, URL.judgeHosDepartmentUser, data);
    },
    // 判断科室是否已存在
    judgeDepartment(data) {
        return request(METHODS.POST, URL.judgeDepartment, data);
    },
    // 获取字典选择框列表
    optionselect(data) {
        return request(METHODS.GET, URL.optionselect, { params: data });
    },
    // 菜单权限
    getRouters(data) {
        return request(METHODS.GET, URL.getRouters, { params: data });
    },
    // 导出人员信息
    exportExcel(data) {
        return request(METHODS.GET, URL.exportExcel, { params: data, responseType: 'arraybuffer' });
    },
//医嘱小类分页列表
    departmentMedicalSort(data) {
        return request(METHODS.GET, URL.departmentMedicalSort, { params: data});
    },
    // 校验医嘱小类是否重复
    depIsExist(data) {
        // return request(METHODS.POST, URL.depIsExist, data);
        return request(METHODS.GET, URL.depIsExist, { params: data});
    },
//     删除医嘱小类
    deleMedical(data) {
        return request(METHODS.POST, URL.deleMedical, data);
    },
    // 新增医嘱小类
    addMedical(data) {
        return request(METHODS.POST, URL.addMedical, data);
    },
    // 回显医嘱小类
    getMedical(data) {
        return request(METHODS.POST, URL.getMedical, data);
    },
    // 修改医嘱小类
    updateMedical(data) {
        return request(METHODS.POST, URL.updateMedical, data);
    },
    // 科室标化工作量
    getDepartmentWorkLoadList(data) {
        return request(METHODS.GET, URL.getDepartmentWorkLoadList, { params: data});
    },
    // 基础科室列表
    pageList(data) {
        return request(METHODS.GET, URL.pageList, { params: data});
    },
    // 删除基础科室
    deletBasicDepartment(data) {
        return request(METHODS.POST, URL.deletBasicDepartment, data);
    },
    // 添加基础科室
    insertBasicDepartment(data) {
        return request(METHODS.POST, URL.insertBasicDepartment, data);
    },
    // 修改基础科室
    updateBasicDepartment(data) {
        return request(METHODS.POST, URL.updateBasicDepartment, data);
    },
    // 科室绩效数据填报列表
    findCurrentYearAndMonthInformation(data) {
        return request(METHODS.GET, URL.findCurrentYearAndMonthInformation, { params: data});
    },
    // 填报
    perdatRep(data) {
        return request(METHODS.POST, URL.perdatRep, data);
    },
    // 审核通过
    approved(data) {
        return request(METHODS.GET, URL.approved, { params: data});
    },
    // 驳回
    reject(data) {
        return request(METHODS.GET, URL.reject, { params: data});
    },
    //查看详情
    findById(data) {
        return request(METHODS.GET, URL.findById, { params: data});
    },
//     核算
    calculate(data) {
        return request(METHODS.POST, URL.calculate, data);
    },
    // 导出绩效数据
    perexport(data) {
        return request(METHODS.GET, URL.perexport, { params: data, responseType: 'arraybuffer' });
    },
    // 填报状态
    fillInStatus(data) {
        return request(METHODS.GET, URL.fillInStatus, { params: data});
    },
    // 绩效总额与占比表
    performanceSumAnalyse(data) {
        return request(METHODS.GET, URL.performanceSumAnalyse, { params: data});
    },
    // 月均绩效与绩效排名分析
    performanceMAvgAnalyse(data) {
        return request(METHODS.GET, URL.performanceMAvgAnalyse, { params: data});
    },
    // 人均绩效与绩效排名分析
    performancePAvgAnalyse(data) {
        return request(METHODS.GET, URL.performancePAvgAnalyse, { params: data});
    },
    //获取时间（大于25为本月）
    getDateTime(data) {
        return request(METHODS.GET, URL.getDateTime, { params: data});
    },
    //重新计算发生量
    recountProjectAmount(data) {
        return request(METHODS.POST, URL.recountProjectAmount, data);
    },
    // 收入绩效关系分析
    incomePerformanceAnalyse(data) {
        return request(METHODS.GET, URL.incomePerformanceAnalyse, { params: data});
    },
    // 有效收入绩效关系分析
    effectiveIncomePerformanceAnalyse(data) {
        return request(METHODS.GET, URL.effectiveIncomePerformanceAnalyse, { params: data});
    },
    // 收入排名，有效收入排名，绩效排名
    incomeEffectiveIncomePerformanceRank(data) {
        return request(METHODS.GET, URL.incomeEffectiveIncomePerformanceRank, { params: data});
    },
    // 药占比 材料占比分析
    materialDrugProportion(data) {
        return request(METHODS.GET, URL.materialDrugProportion, { params: data});
    },
    // 人均收入与排名分析
    incomeAvgRankAnalyse(data) {
        return request(METHODS.GET, URL.incomeAvgRankAnalyse, { params: data});
    },
    // 人均有效收入与排名分析
    effectiveIncomeAvgRankAnalyse(data) {
        return request(METHODS.GET, URL.effectiveIncomeAvgRankAnalyse, { params: data});
    },
    // 人均有效收入与绩效关系分析
    effectiveAvgPerformanceAvgAnalyse(data) {
        return request(METHODS.GET, URL.effectiveAvgPerformanceAvgAnalyse, { params: data});
    },
    // 驱动型绩效数据的总表
    analyseList(data) {
        return request(METHODS.GET, URL.analyseList, { params: data});
    },
    // 导出总表
    exportAnalyse(data) {
        return request(METHODS.GET, URL.exportAnalyse, { params: data, responseType: 'arraybuffer' });
    },
    //导出标化工作量
    exportWorkLoad(data) {
        return request(METHODS.GET, URL.exportWorkLoad, { params: data, responseType: 'arraybuffer' });
    },
    //查询基础科室列表
    findBasicsDepartmentByDepartmentId(data) {
        return request(METHODS.GET, URL.findBasicsDepartmentByDepartmentId, { params: data});
    },
    //查询工作量
    getDepartmentWorkLoad(data) {
        return request(METHODS.GET, URL.getDepartmentWorkLoad, { params: data});
    },
    // 科室人员信息列表
    attpageList(data) {
        return request(METHODS.GET, URL.attpageList, { params: data});
    },
//    新增科室人员信息
    attinsert(data) {
        return request(METHODS.POST, URL.attinsert, data);
    },
    // 修改科室人员信息
    attupdate(data) {
        return request(METHODS.POST, URL.attupdate, data);
    },
    // 删除科室人员信息
    attdelete(data) {
        return request(METHODS.POST, URL.attdelete, data);
    },
    // 考勤管理导出
    attexport(data) {
        return request(METHODS.GET, URL.attexport, { params: data, responseType: 'arraybuffer' });
    },

    // 考核指标列表
    indexList(data) {
        return request(METHODS.POST, URL.indexList, data);
    },
    // 修改基数 分数
    updateIndexDetail(data) {
        return request(METHODS.POST, URL.updateIndexDetail, data);
    },
    // 科室考核得分列表
    indexMonthList(data) {
        return request(METHODS.POST, URL.indexMonthList, data);
    },
    // 修改当月得分
    updateIndexMonth(data) {
        return request(METHODS.POST, URL.updateIndexMonth, data);
    },

    // 重新计算指标 上年基数
    calculateBase(data) {
        return request(METHODS.POST, URL.calculateBase, data);
    },
    // 重新计算指标 上年基数
    calculateMonth(data) {
        return request(METHODS.POST, URL.calculateMonth, data);
    },
    // 重新计算指标 上年基数
    deleteIndex(data) {
        return request(METHODS.POST, URL.deleteIndex, data);
    },
};



export default {
    // 将对象注入到Vue全局
    install(Vue) {
        Vue.prototype.$axios = API;
    }
};

