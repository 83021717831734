<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">科室医疗服务项目管理</h2>
        <el-table
          :data="DeplisttableData"
          border
          center
        >
          <el-table-column
            type="index"
            label="序号"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="科室名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="180"
            align="center"
          >
            <template
              style="display: flex"
              slot-scope="scope"
            >
              <el-button
                size="mini"
                type="primary"
                @click="editJixiao(scope.row)"
              ><i
                  class="el-icon-s-operation"
                  style="font-size: 15px"
                ></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <!-- 点击操作科室医疗服务项目管理 -->
    <el-dialog
      title="科室医疗服务项目管理"
      :visible.sync="DepmedicaldialogVisible"
      width="100%"
      top="2vh"
      :before-close="handleBeforeClose"
    >
      <two-no-ho
          ref="twoNoHoComponent"
        :keshiId='keshiId'
        :hospitalName='hospitalName'
      />
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
import twoNoHo from "./TwoNoHo.vue";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      // 科室医疗服务项目管理弹出框
      DepmedicaldialogVisible: false,
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      //这条数据id
      id: "",
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 科室id
      departmentId: window.sessionStorage.getItem("departmentId"),
      // 科室列表id
      keshiId: "",
      // 科室名称
      hospitalName: "",
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  components: {
    twoNoHo,
  },
  created() {
    this.getDepartmentNotPage();
    if (this.$route.query.departmentId) {
      this.departmentId = this.$route.query.departmentId;
    } else {
      this.departmentId = window.sessionStorage.getItem("departmentId");
    }
  },
  methods: {
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //   获取医院所属科室列表
    async getDepartmentNotPage() {
      let data = {
        hospitalId: this.hospitalId,
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      };
      let { data: res } = await this.$axios.getDepartmentList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取医院所属科室列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      }
    },
    editJixiao(row) {
      this.hospitalName = row.name;
      this.keshiId = row.id;
      this.DepmedicaldialogVisible = true;
    },
    handleBeforeClose(done) {
      this.DepmedicaldialogVisible = false;
      this.hospitalName = "";
      this.keshiId = "";
      if (this.$refs.twoNoHoComponent && typeof this.$refs.twoNoHoComponent.reset === 'function') {
        this.$refs.twoNoHoComponent.reset();
      }
      done(); // 关闭弹窗
    },
  },
};
</script>

<style lang="less" scoped>
.hosform {
  display: flex;
  .hosdanwei {
    margin-left: 10px;
  }
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 30px;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

/deep/ .el-input.el-input--suffix {
  width: 202px;
}
/deep/ .oneBack {
  background: #c4dfec;
}
.el-icon-star-on {
  cursor: pointer;
  font-size: 23px;
  position: relative;
  top: -11px;
  left: -9px;
  color: white;
}
.startActive {
  color: rgb(250, 200, 88);
}

.startBtnJizu {
  width: 44px;
  height: 28px;
  position: relative;
  top: 3px;
}

.startBtn {
  width: 44px;
  height: 28px;
  position: relative;
  top: 14px;
}

.startBtn.el-button:focus,
.startBtn.el-button:hover {
  color: #606266;
  border-color: #dcdfe6;
  background-color: #fff;
}

.twoForm {
  float: right;
  display: flex;
}
</style>
