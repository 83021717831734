<template>
  <!--  人事科 -->
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">考核指标得分管理</h2>
        <div>
          <el-form class="twoForm" :inline="true">
            <el-form-item>
              <el-select v-model="typeValue" placeholder="请选择科室" clearable filterable  @change="typeValueChange(typeValue)" class="xiangType">
                <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-table
            :data="DeplisttableData"
            border
            center
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="科室" align="center">
          </el-table-column>
          <el-table-column prop="updateDate" label="更新时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="220">
            <template style="display: flex" slot-scope="scope">
              <el-button
                  size="mini"
                  type="primary"
                  @click="showDetail(scope.row)"
              >查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog
        :title="fillTitle"
        :visible.sync="FillingVisible"
        :before-close="FillingVisibleClose"
        width="600px"
        top="1vh"
    >
      <el-form
          :model="Performance"
          ref="PerformanceRef"
          :rules="PerformanceRules"
          :inline="true"
          label-width="120px"
      >
        <el-form-item prop="scoreMonth" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              当月得分
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="Performance.scoreMonth" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="btnShow">
          <div class="btnSize">
            <el-button type="primary" @click="onsubmit"
            >提交数据</el-button
            >
          </div>
        </el-form-item>

      </el-form>
    </el-dialog>

    <el-dialog
        :title="numPopupTitle"
        :visible.sync="numPopup"
        :before-close="numPopupClose"
        width="600px"
        top="1vh"
    >
      <el-form
          :model="num"
          ref="numRef"
          :rules="numRules"
          :inline="true"
          label-width="120px"
      >
        <el-form-item prop="doctorNum" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              出诊医师人数
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input :disabled="disabled" v-model="num.doctorNum" :precision="2"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="btnShow">
          <div class="btnSize">
            <el-button type="primary" @click="numSubmit"  :loading="numLoading"
            >测算</el-button
            >
          </div>
        </el-form-item>

      </el-form>
    </el-dialog>

    <el-dialog
        :title="attTit+'考核指标得分管理'"
        :visible.sync="attendVisible"
        :before-close="attendVisibleClose"
        width="90%"
        top='1vh'
    >
      <div>
        <el-form class="twoForm" :inline="true">
          <el-form-item>
            <el-date-picker
                v-model="startTime"
                type="month"
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
                @change="changestartTime"
                placeholder="选择时间">
            </el-date-picker>
          </el-form-item>
        </el-form>

      </div>
      <el-table
          :data="attentableData"
          border
          center
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
      >
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="month" label="月份" width="90" align="center">
        </el-table-column>
        <el-table-column prop="name" label="指标名称" align="center" width="300px">
        </el-table-column>
        <el-table-column prop="weightScore"  label="权重分值" align="center" width="150px">
        </el-table-column>
        <el-table-column prop="cardinal" label="基数" align="center" width="150px">
          <template style="display: flex" slot-scope="scope">
            <div v-if="(scope.row.id == 1 || scope.row.id == 4 || scope.row.id == 6) && scope.row.cardinal">{{scope.row.cardinal+'%'}}</div>
            <div v-else>{{scope.row.cardinal}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="evaluate" label="评价办法" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="baseMonth" label="本月" align="center" width="150px">
          <template style="display: flex" slot-scope="scope">
            <div v-if="(scope.row.id == 1 || scope.row.id == 4 || scope.row.id == 6) && scope.row.baseMonth">{{scope.row.baseMonth+'%'}}</div>
            <div v-else>{{scope.row.baseMonth}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="scoreMonth"  label="本月得分" align="center" width="150px">
        </el-table-column>
        <el-table-column label="操作" align="left" width="167px">
          <template style="display: flex" slot-scope="scope">
            <!--待填写显示填报-->
            <el-button
                size="mini"
                type="primary"
                @click="editIndex(scope.row)"
            >编辑</el-button>
            <el-button style="width: 80px;" v-if="scope.row.id == 5" size="mini" type="primary" @click="calculatePopup(scope.row)">重新计算</el-button>
            <el-button style="width: 80px;" v-else-if="scope.row.id !== 7 && scope.row.id !== 6" size="mini" type="primary" @click="calculateBase(scope.row)" :loading="scope.row.remindLoading">重新计算</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      numLoading:false,
      startTime:'',
      // 导出按钮置灰
      cesuanClick: true,
      cesuanClickTwo: false,
      attendVisible:false,
      numPopup:false,
      numPopupTitle:'',
      deptId:window.sessionStorage.getItem("deptId"),
      depoptions:[],
      typeValue:"",
      attendstartTime:'',
      disabled:false,
      btnShow:true,
      fillTitle:'填报',
      // 填报
      Performance: {
        // 当月得分
        scoreMonth:''
      },
      num:{
        doctorNum:''
      },
      numRules: {
        doctorNum: [
          {
            required: true,
            message: "请输入当月出诊医师数",
            trigger: "blur",
          }
        ]
      },
      // 绩效分配结构规则
      PerformanceRules: {
        scoreMonth: [
          {
            required: true,
            message: "请输入当月得分",
            trigger: "blur",
          }
        ]
      },
      // 填报弹出框
      FillingVisible: false,
      // 表格数据
      DeplisttableData: [],
      attentableData:[],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,

      indexMonthId:'',
      indexName:'',
      attTit:'',
      attDepId:'',

      // 人数填报
      numIndexId:'',
      numIndexDetailId:'',
      numIndexMonthId:'',
      numMonth:''
    };
  },
  created() {
    this.getDepartmentNotPage()
    // 科室数据查询
    this.findDepartment()
  },
  methods: {
    rowClass({ rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        if(columnIndex===6||columnIndex===7){
          return {background:'#FFFAFA','text-align':'center'}
        }if(columnIndex===8){
          return {'text-align':'center'}
        }
      }
    },
    cellStyle({column}){
      if(column.property === 'baseMonth' || column.property === 'scoreMonth'){
        return {
          background: '#FFFAFA'
        }
      }
    },
    numPopupClose(){
      this.numPopup = false;
      this.$refs['numRef'].clearValidate();
      this.num= {
        // 当月医师数
        doctorNum:''

      }
      this.numIndexId = '';
      this.numIndexDetailId = '';
      this.numIndexMonthId = '';
      this.numMonth = '';
    },
    FillingVisibleClose(){
      this.$refs['PerformanceRef'].clearValidate();
      this.FillingVisible=false
      this.indexMonthId=''
      this.indexName=''
      this.scoreMonth=''

      this.Performance= {
        // 当月得分
        scoreMonth:''
      }
    },
    attendVisibleClose(){
      this.attendVisible=false
      this.attDepId=''
      this.attTit=''
      this.getDepartmentNotPage()
    },
    showDetail(row){
      this.attendVisible=true
      this.attTit=row.name
      this.attDepId=row.id
      this.getDateTime()
    },
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.indexList();
    },
    changestartTime(val){
      this.startTime=val
      this.indexList();
    },
    async indexList() {
      let data = _qs.stringify({
        departmentId:this.attDepId,
        month:this.startTime
      });
      let { data: res } = await this.$axios.indexMonthList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.attentableData = res.rows;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    typeValueChange(val){
      this.typeValue=val
      this.getDepartmentNotPage();
    },
    calculatePopup(row){
      this.numPopupTitle = this.attTit+row.month+row.name+'测算';
      // 弹窗
      this.numPopup=true;

      this.numIndexId = row.id;
      this.numIndexDetailId = row.detailsId;
      this.numIndexMonthId = row.assessTargetMonthId;
      this.numMonth = row.month;
    },
    async calculateBase(row){
      this.$set(row, 'remindLoading', true)
      let data = _qs.stringify({
        id:row.id,
        detailsId:row.detailsId,
        departmentId:this.attDepId,
        month:row.month,
        assessTargetMonthId:row.assessTargetMonthId
      });
      let res = await this.$axios.calculateMonth(data);

      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.indexList()
        this.FillingVisible=false
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
      this.$set(row, 'remindLoading', false)
    },
    async findDepartment(){
      let data = {
        hospitalId:window.sessionStorage.getItem("hospitalId"),
        isWorkload:1
      };
      let { data: res } = await this.$axios.findDepartment(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.depoptions = res.data;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    editIndex(row) {
      console.log(row.assessTargetMonthId)
      this.indexMonthId=row.assessTargetMonthId
      this.indexName=row.name
      this.scoreMonth=row.scoreMonth
      this.fillTitle=this.attTit+row.month+row.name+'指标分数修改'

      this.Performance.scoreMonth = row.scoreMonth

      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //   获取绩效填报列表
    async getDepartmentNotPage() {
      let data = {
        basePeriodTime:this.attendstartTime,//时间
        hospitalId:window.sessionStorage.getItem("hospitalId"),
        id:this.typeValue,
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        isWorkload: 1
      };
      let { data: res } = await this.$axios.getDepartmentList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    numSubmit(){
      this.$refs.numRef.validate(async(valid) => {
        if (valid) {
          this.numLoading = true;
          let data = _qs.stringify({
            id:this.numIndexId,
            detailsId:this.numIndexDetailId,
            departmentId:this.attDepId,
            month:this.numMonth,
            assessTargetMonthId:this.numIndexMonthId,
            sumUser:this.num.doctorNum
          });
          let res = await this.$axios.calculateMonth(data);

          if (res.data.code == 401) {
            this.$router.push("/login");
          } else if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });

            this.numPopupClose();
            this.numLoading = false;
            this.indexList()
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        }
      })
    },
    onsubmit() {
      this.$refs.PerformanceRef.validate(async(valid) => {
        if (valid) {
          let data = _qs.stringify({
            id:this.indexMonthId,
            score:this.Performance.scoreMonth // 当月得分
          });
          let res = await this.$axios.updateIndexMonth(data);

          // console.log(res);
          if (res.data.code == 401) {
            this.$router.push("/login");
          } else if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.indexList();
            this.FillingVisible=false
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 500px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
}

.twoForm {
  float: right;
  display: flex;
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnSize button{
  width: 120px;
  margin-top: 20px;
}

/deep/ .el-input-number__decrease,/deep/ .el-input-number__increase{
  display: none;
}

.checRev /deep/ .el-checkbox{
  margin-bottom: 20px;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #ffffff;
}
.key_color{
  background: lavender;
}
</style>
