import { render, staticRenderFns } from "./Basicdata.vue?vue&type=template&id=02902eeb&scoped=true&"
import script from "./Basicdata.vue?vue&type=script&lang=js&"
export * from "./Basicdata.vue?vue&type=script&lang=js&"
import style0 from "./Basicdata.vue?vue&type=style&index=0&id=02902eeb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02902eeb",
  null
  
)

export default component.exports