<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle" v-if="departmentId !== 'null'">科室工作量数据管理</h2>
        <el-table
            :data="YearListtableData"
            border
            center
        >
          <el-table-column
              type="index"
              label="序号"
              width="55"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="year"
              label="基期年份"
              align="center"
          >
          </el-table-column>
          <el-table-column
              width="180"
              label="填报状态"
              align="center"
          >
            <template slot-scope="scope">
              <div
                  class="activeStatus"
                  v-if="scope.row.status == 1"
              >√</div>
              <div
                  class="noStatus"
                  v-else
              >√</div>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              align="center"
              width="130"
          >
            <template
                style="display: flex"
                slot-scope="scope"
            >
              <el-button
                  size="mini"
                  type="primary"
                  @click="dateYear(scope.row)"
              ><i class="el-icon-notebook-2"></i></el-button>
              <el-button
                  size="mini"
                  type="primary"
                  @click="editWork(scope.row)"
              ><i class="el-icon-document-copy"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentYearListChange"
            :current-page="YearListpageNum"
            :page-size="YearListpageSize"
            layout="prev, pager, next"
            :total="YearListtotal"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <!-- 2015年科室工作量填报弹出框 -->
    <el-dialog
        :title="yeartext"
        :visible.sync="EditdialogVisible"
        width="80%"
        :before-close="EditdialogVisibleClose"
        top="1vh"
        append-to-body
    >
      <el-table
          :data="YeartableData"
          style="width: 95%; margin: 0 auto"
          border
          center
      >
        <el-table-column
            prop="month"
            label="月份"
            width="100"
            align="center"
        >
        </el-table-column>
        <el-table-column
            label="填报状态"
            align="center"
        >
          <template slot-scope="scope">
            <div
                class="activeStatus"
                v-if="scope.row.status == 1"
            >√</div>
            <div
                class="noStatus"
                v-else
            >√</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="totalPrice"
            label="有效收入(元)"
            align="center"
        >
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
        >
          <template
              style="display: flex"
              slot-scope="scope"
          >
            <el-button
                size="mini"
                type="primary"
                @click="editMonth(scope.row)"
            ><i class="el-icon-document-copy"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 月份科室工作量填报弹出框 -->
    <el-dialog
        :title="yearMonthtext"
        :visible.sync="MounthdialogVisible"
        width="80%"
        :before-close="MounthdialogVisibleClose"
        top="1vh"
        append-to-body
    >
      <div class="gjtbao">
        <el-form
            :inline="true"
            class="gjtform"
        >
          <el-form-item>
            <el-button
                type="primary"
                @click="recalculate"
                :loading="recaExpClick"
            >重新计算</el-button>
            <el-button
                type="primary"
                v-if="cesuanExpClick"
                @click="exportPriceRatioMonthDetails"
                :loading="cesuanExpClickTwo"
            >导出工作量数据</el-button>
            <el-button
                type="info"
                v-else
                :loading="cesuanExpClickTwo"
            ></el-button>
          </el-form-item>
          <el-form-item label="项目类型：">
            <el-select
                v-model="YearDataListvalue"
                placeholder="请选择"
                clearable
                @change="typeYearValueChange(YearDataListvalue)"
            >
              <el-option
                  v-for="item in YearDataListoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-table
          :data="MounthtableData"
          style="width: 95%; margin: 0 auto"
          ref="mountable"
          border
          center
      >
        <el-table-column
            type="index"
            :index="MounthindexMethod"
            align="center"
            label="序号"
            width="55"
        ></el-table-column>
        <el-table-column
            prop="projectCode"
            label="项目编码"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="priceCode"
            label="收费编码"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="项目名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="currentPrice"
            label="现行收费价格"
            align="center"
            width="130"
        >
        </el-table-column>
        <el-table-column
            prop="billingTotal"
            label="开单发生量"
            align="center"
            sortable
            width="120"
        >

        </el-table-column>
        <el-table-column
            prop="executeTotal"
            label="执行发生量"
            align="center"
            sortable
            width="120"
        >
        </el-table-column>
        <el-table-column
            prop="revenues"
            label="项目总收入"
            align="center"
            width="120"
            sortable

        >
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @current-change="handleCurrentMounthChange"
          :current-page="MounthpageNum"
          :page-size="MounthpageSize"
          layout="prev, pager, next"
          :total="Mounthtotal"
      >
      </el-pagination>
    </el-dialog>
    <!-- 年度医疗服务项目数据 -->
    <el-dialog
        :title="dataYeartext"
        :visible.sync="YearhdialogVisible"
        width="80%"
        :before-close="YearhdialogVisibleClose"
        top="1vh"
        append-to-body
    >
      <el-form
          class="twoForm"
          :inline="true"
          :model="getDataListModel"
      >
        <div class="twoFormDiv">
          <el-form-item
              label="项目类型："
              class="FormType"
          >
            <el-select
                v-model="Yearvalue"
                placeholder="请选择"
                clearable
                @change="typeYearDataListValueChange(Yearvalue)"
            >
              <el-option
                  v-for="item in Yearoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-table
          :data="YearTotaltableData"
          ref="yearTable"
          style="width: 95%; margin: 0 auto"
          border
          center
      >
        <el-table-column
            type="index"
            :index="indexMethod"
            align="center"
            label="序号"
            width="55"
        ></el-table-column>
        <el-table-column
            prop="projectCode"
            label="项目编码"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="priceCode"
            label="收费编码"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="项目名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="currentPrice"
            label="现行收费价格"
            align="center"
            width="105"
        >
        </el-table-column>
        <el-table-column
            prop="billingTotal"
            label="年度开单发生量"
            align="center"
            width="145"
            sortable
        >
        </el-table-column>
        <el-table-column
            prop="executeTotal"
            label="年度执行发生量"
            align="center"
            width="145"
            sortable
        >
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="total"-->
<!--            label="年度发生量"-->
<!--            align="center"-->
<!--            width="120"-->
<!--            :render-header="(h) => renderHeader(h, '年度发生量', 'year')"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            prop="revenues"
            label="项目总收入"
            align="center"
            width="120"
            sortable
        >
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @current-change="handleCurrentYearChange"
          :current-page="YearpageNum"
          :page-size="YearpageSize"
          layout="prev, pager, next"
          :total="Yeartotal"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog
        title="正在导入数据，请稍等~"
        :visible.sync="dialogVisibleloding"
        width="30%"
        :show-close="false"
        center
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
    </el-dialog>
    <el-dialog
        title="Excel文档中未导入的工作量数据"
        :visible.sync="dialogMisVisibleloding"
        width="70%"
        :before-close="dialogMisVisiblelodingClose"
        top="1vh"
    >
      <el-table
          :data="mistaltableData"
          style="width: 95%; margin: 0 auto"
          border
          center
      >
        <el-table-column
            type="index"
            label="序号"
            width="55"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="projectCode"
            label="项目编码"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="priceCode"
            label="收费编码"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="项目名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="currentPrice"
            label="现行收费价格"
            align="center"
            width="105"
        >
        </el-table-column>
        <el-table-column
            prop="total"
            label="发生量"
            align="center"
            width="120"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  props: ["depart", "hospital", "depName"],
  data() {
    return {
      //导出按钮置灰
      cesuanExpClick: true,
      cesuanExpClickTwo: false,
      recaExpClick:false,
      dialogVisibleloding: false,
      // 未匹配数据的弹出框
      dialogMisVisibleloding: false,
      // 未匹配数据的表格
      mistaltableData: [],
      departmentId: '',
      Yearoptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "收费项目",
        },
        {
          value: "0",
          label: "非收费项目",
        },
      ],
      Yearvalue: "",
      //  科室年度数据的搜索
      YearDataListoptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "收费项目",
        },
        {
          value: "0",
          label: "非收费项目",
        },
      ],
      YearDataListvalue: "",
      // 2015年科室工作量填报弹出框
      EditdialogVisible: false,
      //   月份科室工作量填报弹出框
      MounthdialogVisible: false,
      //   年度医疗服务项目数据
      YearhdialogVisible: false,
      // 科室年度数据分页
      // 当前页数
      YearpageNum: 1,
      // 每页显示条数
      YearpageSize: 10,
      // 总页数
      Yeartotal: 1,
      // 月份分页
      // 当前页数
      MounthpageNum: 1,
      // 每页显示条数
      MounthpageSize: 10,
      // 总页数
      Mounthtotal: 1,
      //   科室年份列表 分页
      // 当前页数
      YearListpageNum: 1,
      // 每页显示条数
      YearListpageSize: 10,
      // 总页数
      YearListtotal: 1,
      // 科室年份列表数据
      YearListtableData: [],
      //   科室年份详情列表数据
      YeartableData: [],
      //   科室月份工作量数据
      MounthtableData: [],
      // 获取科室年度数据
      YearTotaltableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 2015年年度医疗服务项目数据
      getDataListModel: {
        searchValue: "",
      },
      //这条数据id
      id: "",
      //   科室月份数据id
      did: "",
      //   科室年份数据id
      yid: "",
      // 现行收费价格
      currentPrice: "",
      // 发生量
      total: "",
      executeTotal:"",//执行发生量
      billingTotal:"",//开单发生量
      // 排序数字
      sortNum: 0,
      // 排序方法
      isAsc: "",
      // 年科室工作量填报的标题
      yeartext: "",
      // 年月科室工作量填报的标题
      yearMonthtext: "",
      // 2015年年度医疗服务项目数据的标题
      dataYeartext: "",
      // 年份
      yearValue: "",
      // 月份
      ymonth: "",
      // 排序参数
      orderBy: "",
      downurl: this.GLOBAL + "/openApi/integrated/importPriceRatioExcel",
      fileList: [], // 上传文件的列表
      limitUpload: 1, //最大允许上传个数
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  created() {
    this.getDepartmentYearList();
  },
  methods: {
    // 重新计算
    async recalculate(){
      this.recaExpClick=true
      let data = _qs.stringify({
        id: this.did
      });
      let { data: res } = await this.$axios.recountProjectAmount(data);
      // console.log(res);
      this.recaExpClick=false
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.getDepartmentPriceRatioList()
      } else if (res.code == 500) {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }

    },
    //设置表格翻页的序号递增
    indexMethod(index) {
      let YearpageNum = this.YearpageNum; // 当前页码
      let YearpageSize = this.YearpageSize; // 每页条数
      return index + 1 + (YearpageNum - 1) * YearpageSize; // 返回表格序号
    },
    MounthindexMethod(index) {
      let MounthpageNum = this.MounthpageNum; // 当前页码
      let MounthpageSize = this.MounthpageSize; // 每页条数
      return index + 1 + (MounthpageNum - 1) * MounthpageSize; // 返回表格序号
    },
    dialogMisVisiblelodingClose() {
      this.dialogMisVisibleloding = false;
    },
    // 导入成功后执行此操作
    success(res) {
      // console.log(res, "导入");
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        setTimeout(() => {
          this.$message.success("导入成功");
          this.dialogVisibleloding = false;
        }, 1000);
        this.dialogMisVisibleloding = true;
        this.mistaltableData = res.mismatch;
        this.getDepartmentPriceRatioList();
        this.$refs.uploadone.clearFiles();
      } else {
        this.$refs.uploadone.clearFiles();
        this.$message({
          message: res.msg,
          type: "error",
        });
        // this.$alert(res.msg, {
        //   confirmButtonText: "确定",
        //   callback: () => {
        this.dialogVisibleloding = false;
        //   },
        // });
      }
    },
    // excel导入过程中
    uploading() {
      this.dialogVisibleloding = true;
    },
    // 查询科室年份列表 分页
    async getDepartmentYearList() {
      let data
      // 医院管理员 101  科室管理员 102
      if(window.sessionStorage.getItem("deptId")=='101'){
        data = {
          pageNum: this.YearListpageNum, //页数
          pageSize: this.YearListpageSize, //每页显示条数
          departmentId: this.depart, //科室id
        };
      }

      else if(window.sessionStorage.getItem("deptId")=='102' && window.sessionStorage.getItem("departmentName") == '绩效办'){
        data = {
          pageNum: this.YearListpageNum, //页数
          pageSize: this.YearListpageSize, //每页显示条数
          departmentId: this.depart, //科室id
        };
      }

      else if(window.sessionStorage.getItem("deptId")=='102'){
        data = {
          pageNum: this.YearListpageNum, //页数
          pageSize: this.YearListpageSize, //每页显示条数
          departmentId: window.sessionStorage.getItem("departmentId"), //科室id
        };
      }

      let { data: res } = await this.$axios.getDepartmentYearList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.YearListtotal = res.total;
        this.YearListtableData = res.rows;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    currentPriceBlur(row) {
      if (row.currentPrice) {
        this.currentPrice = row.currentPrice;
        this.updateDepartmentPriceRatio(row);
      }
    },
    EditdialogVisibleClose() {
      this.EditdialogVisible = false;
      this.getDepartmentYearList();
    },
    MounthdialogVisibleClose() {
      this.YearDataListvalue = "";
      this.MounthdialogVisible = false;
      // this.orderby(1, 1, 2);
      this.MounthpageNum = 1;
      this.$refs.mountable.clearSort()
    },
    // 发生量失去焦点
    totalBlur(row) {
      if (row.billingTotal) {
        this.billingTotal = row.billingTotal;
        this.updateDepartmentPriceRatio(row);
      }
    },
    // 执行发生量失去焦点
    exetotalBlur(row){
      if (row.executeTotal) {
        this.executeTotal = row.executeTotal;
        this.updateDepartmentPriceRatio(row);
      }
    },
    async updateDepartmentPriceRatio(row) {
      let data = _qs.stringify({
        id: row.id, //此条数据id
        yId: this.yid, //科室年份数据id
        currentPrice: row.currentPrice, //现行收费价格
        billingTotal: row.billingTotal, //开单发生量
        executeTotal:row.executeTotal,//执行发生量
      });
      let { data: res } = await this.$axios.updateDepartmentPriceRatio(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.MounthtableData.revenues = res.data;
        this.getDepartmentPriceRatioList();
        this.editWork();
      } else if (res.code == 500) {
        this.$message({
          message: "修改单条填报数据失败！发生量和现行收费价格都不能为空。",
          type: "error",
        });
      }
    },
    // 科室年度数据分页
    handleCurrentYearChange(val) {
      this.YearpageNum = val;
      this.getDepartmentYearDataList();
    },
    // 月份的分页
    handleCurrentMounthChange(val) {
      this.MounthpageNum = val;
      // console.log(`当前页: ${val}`);
      this.getDepartmentPriceRatioList();
    },
    // 科室年份列表的分页
    handleCurrentYearListChange(val) {
      this.YearListpageNum = val;
      // console.log(`当前页: ${val}`);
      this.getDepartmentYearList();
    },
    editdialogKeshi() {},
    // 点击操作显示2015年科室工作量填报弹出框
    async editWork(row) {
      if (row) {
        this.yid = row.id;
        this.yearValue = row.year;
        this.yeartext = row.year + "年科室工作量数据管理";
      }
      //   查询科室年份详情列表 分页
      let data = {
        pageNum: 1, //页数 只有一页
        pageSize: 12, //每页显示条数 这个直接显示12个月的
        yId: this.yid, //科室年份表id
      };
      let { data: res } = await this.$axios.getDepartmentYearDetailsList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.EditdialogVisible = true;
        this.YeartableData = res.rows;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 科室年度数据的项目类型
    typeYearDataListValueChange(Yearvalue) {
      this.Yearvalue = Yearvalue;
      this.YearpageNum = 1;
      this.getDepartmentYearDataList();
    },
    // 科室月份工作量数据的项目类型
    typeYearValueChange(YearDataListvalue) {
      this.YearDataListvalue = YearDataListvalue;
      this.MounthpageNum = 1;
      this.getDepartmentPriceRatioList();
    },
    // 点击月份的操作
    async editMonth(row) {
      this.did = row.id;
      this.yid = row.yId;
      this.ymonth = row.month;
      this.yearMonthtext =
          this.yearValue + "年" + row.month + "月科室工作量数据管理";
      this.getDepartmentPriceRatioList();
    },
    async getDepartmentPriceRatioList() {
      let data = {
        pageNum: this.MounthpageNum, //页数
        pageSize: this.MounthpageSize, //每页显示条数
        dId: this.did, //科室月份数据id
        type: this.YearDataListvalue, //项目类型（0 非收费项目 1 收费项目 ）不传此参数就是全部
        yId: this.yid, //科室年份id
        orderByColumn: this.orderBy, //排序参数（currentPrice 现行收费价格 total 发生量）
        isAsc: this.isAsc //sc 升序 desc降序
      };

      let { data: res } = await this.$axios.getDepartmentPriceRatioList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.MounthdialogVisible = true;
        this.Mounthtotal = res.total;
        this.MounthtableData = res.rows;
        let num = 0;
        res.rows.map((item) => {
          if (
              item.currentPrice &&
              item.total &&
              item.revenues &&
              item.diffClass
          ) {
            num += 1;
          }
        });
        if (num == 7) {
          this.YeartableData[this.ymonth - 1]["deleteFlag"] = 1;
        }
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 点击年度数据
    dateYear(row) {
      this.yid = row.id;
      this.dataYeartext = row.year + "年年度医疗服务项目数据";
      this.getDepartmentYearDataList();
    },
    getDepartmentYearData() {
      this.YearpageNum = 1;
      this.getDepartmentYearDataList();
    },
    // 获取科室年度数据 分页
    async getDepartmentYearDataList() {
      let data = {
        pageNum: this.YearpageNum, //页数
        pageSize: this.YearpageSize, //每页显示条数
        yId: this.yid, //科室年份数据id
        searchValue: this.getDataListModel.searchValue, //科室名称或编码
        type: this.Yearvalue, //项目类型 0 非收费项目 1 收费项目
        orderByColumn: this.orderBy, //排序参数（total 年度发生量 revenues 总收入diff_class 难度系数）不需要排序的话可不传
        isAsc: this.isAsc, //排序顺序 asc 升序 desc 降序
      };
      let { data: res } = await this.$axios.getDepartmentYearDataList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.YearhdialogVisible = true;
        this.YearTotaltableData = res.rows;
        this.Yeartotal = res.total;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    YearhdialogVisibleClose() {
      this.YearpageNum = 1;
      this.Yearvalue = "";
      this.getDataListModel.searchValue = "";
      this.YearhdialogVisible = false;
      this.$refs.yearTable.clearSort()
    },
    // 导出工作量数据
    async exportPriceRatioMonthDetails() {
      this.cesuanExpClick = false;
      this.cesuanExpClickTwo = true;
      let data = {
        dId: this.did, //月份数据id
        yId: this.yid, //年份数据id
      };
      let res = await this.$axios.exportPriceRatioMonthDetails(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 500) {
        this.$message({
          message: "导出失败",
          type: "error",
        });
      } else if (res.status == 200) {
        let blob = new Blob([res.data], { type: "application/x-xls" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        //配置下载的文件名
        link.download = this.yearMonthtext + ".xls";
        link.click();
        this.cesuanExpClick = true;
        this.cesuanExpClickTwo = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.gjtbao {
  width: 95%;
  margin: 0 auto;
}

.gjtform {
  float: right;
}

.addprimary {
  margin-top: 10px;
}

.twoForm {
  width: 95%;
  margin: 0 auto;
  .twoFormDiv {
    float: right;
    .FormType {
      margin-right: 0;
    }
  }
}
.el-icon-d-caret {
  cursor: pointer;
}
.depar-dia {
  width: 100%;
}
.hosrow {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
  .Basicbutto {
    float: right;
  }
}

.fashengum {
  width: 80px;
  border: none;
  height: 40px;
  text-align: center;
}

.fashengum:focus {
  outline: none;
  border: 1px solid #347ede;
}

.hosform {
  display: flex;
  .hosdanwei {
    margin-left: 10px;
  }
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

/deep/ .el-upload-list {
  display: none;
}
</style>
