<template>
  <el-container>
    <el-main class="mainClas">
    <div>
      <el-form class="twoForm" :inline="true">
        <el-form-item label="开始时间：" class="timClas">
          <el-date-picker
              v-model="startTime"
              type="month"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM"
              @change="changeTime"
              placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" class="timClas">
          <el-date-picker
              v-model="endTime"
              type="month"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM"
              @change="changeTime"
              placeholder="选择结束时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="表格" name="first">
          <div>
          <div style="margin-bottom: 20px;float: right" >
            <el-button
                type="primary"
                v-if="expExpClick"
                @click="exportdata"
                :loading="expExpClickTwo"
            >导出数据</el-button>
            <el-button
                type="info"
                v-else
                :loading="expExpClickTwo"
            ></el-button>
          </div>
            <el-table
                :data="tableData"
                border
                center
                style="width: 100%">
              <el-table-column
                  prop="departmentName"
                  label="科室名称" align="center">
              </el-table-column>
              <el-table-column
                  prop="performance"
                  label="绩效总额" align="center" width="110">
              </el-table-column>
              <el-table-column
                  prop="performance"
                  label="绩效" align="center" width="110">
              </el-table-column>
              <el-table-column
                  prop="numOfPersonnel"
                  label="绩效人数" align="center">
              </el-table-column>
              <el-table-column
                prop="proportion"
                label="科室绩效占比" align="center">
            </el-table-column>
              <el-table-column
                prop="performanceAvg"
                label="平均每月绩效" align="center" width="110">
            </el-table-column>
              <el-table-column
                prop="performanceRank"
                label="绩效排名" align="center">
            </el-table-column>
              <el-table-column
                  prop="income"
                  label="总收入" align="center" width="110">
              </el-table-column>
              <el-table-column
                prop="incomeRank"
                label="收入排名" align="center">
            </el-table-column>
              <el-table-column
                prop="drugIncome"
                label="药品收入" align="center" width="110">
            </el-table-column>
              <el-table-column
                prop="materialIncome"
                label="材料收入" align="center" width="110">
            </el-table-column>
              <el-table-column
                prop="effectiveIncome"
                label="有效收入" align="center" width="110">
            </el-table-column>
              <el-table-column
                  prop="effectiveIncomeRank"
                  label="有效收入排名" align="center">
              </el-table-column>
              <el-table-column
                  prop="performanceOfPAvg"
                  label="人均绩效" align="center">
              </el-table-column>
              <el-table-column
                  prop="performanceOfPAvgRank"
                  label="人均绩效排名" align="center">
              </el-table-column>
              <el-table-column
                  prop="incomeAvg"
                  label="人均收入" align="center" width="110">
              </el-table-column>
              <el-table-column
                  prop="incomeAvgRank"
                  label="人均收入排名" align="center">
              </el-table-column>
              <el-table-column
                  prop="effectiveIncomeAvg"
                  label="人均有效收入" align="center">
              </el-table-column>
              <el-table-column
                  prop="effectiveIncomeAvgRank"
                  label="人均有效收入排名" align="center">
              </el-table-column>
              <el-table-column
                  prop="personAvgProportion"
                  label="人均绩效占人均有效收入比例" align="center" width="130">
              </el-table-column>
              <el-table-column
                  prop="drugProportion"
                  label="药品占比" align="center">
              </el-table-column>
              <el-table-column
                  prop="materialProportion"
                  label="材料占比" align="center">
              </el-table-column>
              <el-table-column
                  prop="effectiveIncomeProportion"
                  label="有效收入占比" align="center">
              </el-table-column>
              <el-table-column
                  prop="performanceIncomeProportion"
                  label="绩效占收入比值" align="center">
              </el-table-column>
              <el-table-column
                  prop="performanceEIncomeProportion"
                  label="绩效占有效收入比值" align="center" width="110">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="绩效总额与占比表" name="second">
          <div>
            <div id="covwith" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="收入绩效关系分析" name="third">
          <div>
            <div id="cylindricality" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="有效收入绩效关系分析" name="fourth">
          <div>
            <div id="fourChat" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="收入、有效收入、绩效排名对比" name="five">
          <div>
            <div id="fiveChat" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="药占比与材料占比分析" name="six">
          <div>
            <div id="sixChat" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="月均绩效与绩效排名分析" name="seven">
          <div>
            <div id="sevenChart" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人均绩效与排名分析" name="eight">
          <div>
            <div id="eightChart" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人均收入与排名分析" name="nine">
          <div>
            <div id="nineChart" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人均有效收入与排名分析" name="ten">
          <div>
            <div id="tenChart" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人均有效收入与绩效关系分析" name="eleven">
          <div>
            <div id="elevenChart" :style="{width: '100%', height: '500px'}"></div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
import it from "element-ui/src/locale/lang/it";

export default {
  data() {
    return {
      activeName: 'first',
      startTime:'',
      endTime:"",
      tableData: [],
      //导出按钮置灰
      expExpClick: true,
      expExpClickTwo: false,
    };
  },
  mounted(){
    this.getDateTime()
  },
  methods: {
    //获取当前年月日
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const day = date.date >= 10 ? date.date : "0" + date.date;
      let updateTime = date.year + "-" + newmonth + "-" + day;
      return updateTime;
    },
    // 导出数据
    async exportdata() {
      this.expExpClick = false;
      this.expExpClickTwo = true;
      let data={
        startDate:this.startTime,
        endDate:this.endTime
      }
      let res = await this.$axios.exportAnalyse(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 500) {
        this.$message({
          message: res.msg,
          type: "error",
        });
      } else if (res.status == 200) {
        let updateTime = this.addDate();
        let blob = new Blob([res.data], { type: "application/x-xls" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        //配置下载的文件名
        link.download = '总表' + updateTime + ".xls";
        link.click();
        this.expExpClick = true;
        this.expExpClickTwo = false;
      }
    },
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.endTime=res
      this.determine()
    },
    determine(){
      if(this.activeName=='first'){
        // 总表
        this.analyseList()
      }else if(this.activeName=='second'){
        // 绩效总额与占比表
        this.performanceSumAnalyse()
      }else if(this.activeName=='seven'){
        // 月均绩效与绩效排名分析
        this.performanceMAvgAnalyse()
      }else if(this.activeName=='eight'){
        // 人均绩效与排名分析
        this.performancePAvgAnalyse()
      }else if(this.activeName=='third'){
        // 收入绩效关系分析
        this.incomePerformanceAnalyse()
      }else if(this.activeName=='fourth'){
        // 有效收入绩效关系分析
        this.effectiveIncomePerformanceAnalyse()
      }else if(this.activeName=='five'){
        // 收入、有效收入、绩效排名对比
        this.incomeEffectiveIncomePerformanceRank()
      }else if(this.activeName=='six'){
        // 药占比与材料占比分析
        this.materialDrugProportion()
      }else if(this.activeName=='nine'){
        // 人均收入与排名分析
        this.incomeAvgRankAnalyse()
      }else if(this.activeName=='ten'){
        // 人均有效收入与排名分析
        this.effectiveIncomeAvgRankAnalyse()
      }else if(this.activeName=='eleven'){
        // 人均有效收入与绩效关系分析
        this.effectiveAvgPerformanceAvgAnalyse()
      }
    },
    changeTime(){
      this.determine()
    },
    // 总表
    async analyseList(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.analyseList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.tableData=res.data
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 人均绩效与排名分析
    async performancePAvgAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.performancePAvgAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],performanceArr=[],proportionArr=[]
        res.data.forEach(item=>{
          departArr.push(item.departmentName)
          performanceArr.push(item.performanceOfPAvg)
          proportionArr.push(item.proportion)
        })
        this.drawEight(departArr,performanceArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 人均绩效与排名分析
    drawEight(departArr,performanceArr){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('eightChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center',
          text: '人均绩效与排名分析'
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: departArr,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              // rotate: 45, // 旋转角度
              interval: 0 // 强制显示所有标签
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            areaStyle: {},
            label: {
              formatter: function(params) {
                return params.data
              },
              show: true,
              position: 'top',
            },
            data: performanceArr,
            lineStyle:{
              normal: {
                color: '#1890ff', //线的颜色
                type: 'solid', //线的类型 'solid'（实线）'dashed'（虚线）'dotted'（点线）
              },
            },
            itemStyle: {
              color: "#1890ff",// 小圆点的颜色
            },
          }
        ]
      });
    },
    // 月均绩效与绩效排名分析
    async performanceMAvgAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.performanceMAvgAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],performanceArr=[],proportionArr=[]
        res.data.forEach(item=>{
          departArr.push(item.departmentName)
          performanceArr.push(item.performanceAvg)
          proportionArr.push(item.proportion)
        })
        this.drawSeven(departArr,performanceArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 月均绩效与绩效排名分析
    drawSeven(departArr,performanceArr){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('sevenChart'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: departArr,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              // rotate: 45, // 旋转角度
              interval: 0 // 强制显示所有标签
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            areaStyle: {},
            label: {
              formatter: function(params) {
                return params.data
              },
              show: true,
              position: 'top',
            },
            data: performanceArr,
            lineStyle:{
              normal: {
                color: '#1890ff', //线的颜色
                type: 'solid', //线的类型 'solid'（实线）'dashed'（虚线）'dotted'（点线）
              },
            },
            itemStyle: {
              color: "#1890ff",// 小圆点的颜色
            },
          }
        ]
      });
    },
    // 绩效总额与占比表
    async performanceSumAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.performanceSumAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],performanceArr=[],proportionArr=[]
        let reg2 = new RegExp("%"); // 不加'g'，仅删除字符串里第一个"a"
        res.data.performanceProportionDoList.forEach(item=>{
          departArr.push(item.departmentName)
          performanceArr.push(item.performance)
          proportionArr.push(item.proportion)
        })
        let totalperform=res.data.performanceSum
        this.drawCowi(departArr,performanceArr,proportionArr,totalperform);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 绩效总额与占比表
    drawCowi(data,performanceArr,proportionArr,totalperform){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('covwith'))
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center',
          text: '绩效总额：'+totalperform
        },
        tooltip: {
          trigger: "axis",// 鼠标指的时候显示文字
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data,
          axisLabel: {
            // rotate: 45, // 旋转角度
            interval: 0 // 强制显示所有标签
          }
        },
        yAxis: [
          {
            name: '单位(元)',
            type: 'value',
          },
        ],
        series: [
          {
            type: 'line',
            areaStyle: {},
            label: {
              formatter: function(params) {
                return params.data +"\n"+ proportionArr[params.dataIndex]
              },
              show: true,
              position: 'top',
            },
            data: performanceArr,
            lineStyle:{
              normal: {
                color: '#1890ff', //线的颜色
                type: 'solid', //线的类型 'solid'（实线）'dashed'（虚线）'dotted'（点线）
              },
            },
            itemStyle: {
              color: "#1890ff",// 小圆点的颜色
            },
          }
        ]
      });
    },
    handleClick(tab, event) {
      this.activeName=tab.name
      this.determine()
    },
    // 收入绩效关系分析
    async incomePerformanceAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.incomePerformanceAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],performanceArr=[],proportionArr=[],effectiveArr=[]
        let reg2 = new RegExp("%"); // 不加'g'，仅删除字符串里第一个"a"
        res.data.forEach(item=>{
          // console.log(item)
          departArr.push(item.departmentName)
          performanceArr.push(item.performance)
          proportionArr.push(Number(item.performanceIncomeProportion.split('%')[0]))
          effectiveArr.push(item.income)
        })
        this.drawCylin(departArr,performanceArr,proportionArr,effectiveArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 收入绩效关系分析
    drawCylin(departArr,performanceArr,proportionArr,effectiveArr){
      // console.log(departArr,performanceArr,proportionArr,effectiveArr)
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('cylindricality'))
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center',
          text: '收入绩效关系分析'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          formatter(params) {
            let str = "";
            str += `<div>
                  <p/><p>
                   ${ params[0].axisValue}：
                    </br>
                    ${
                params[0].seriesName+'：'+ params[0].value
            }
                  </br>
                   ${
                params[1].seriesName+'：'+ params[1].value
            }
                  </br>
                   ${
                params[2].seriesName+'：'+ params[2].value+'%'
            }
                  </div>`;
            return str;
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        legend: {
          left:0
        },
        xAxis: [
          {
            type: 'category',
            data: departArr,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              // rotate: 45, // 旋转角度
              interval: 0 // 强制显示所有标签
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '收入',
            type: 'bar',
            label: {
              show: true,
              position: 'inside'
            },
            // itemStyle:{
            //   normal: {
            //     color: '#589cf4', //线的颜色
            //   },
            // },
            data: effectiveArr
          },
          {
            name: '绩效',
            type: 'bar',
            label: {
              show: true,
              position: 'inside'
            },
            // itemStyle:{
            //   normal: {
            //     color: '#ffab85', //线的颜色
            //   },
            // },
            data: performanceArr
          },
          {
            name: '占比',
            type: 'line',
            yAxisIndex: 1,
            label: {
              formatter: function(params) {
                return params.data +"%"
              },
              show: true,
              position: 'inside',
            },
            // lineStyle:{
            //   normal: {
            //     color: '#ff7d43', //线的颜色
            //   },
            // },
            data: proportionArr
          }
        ]
      });
    },
    // 有效收入绩效关系分析
    async effectiveIncomePerformanceAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.effectiveIncomePerformanceAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],performanceArr=[],proportionArr=[],effectiveArr=[]
        let reg2 = new RegExp("%"); // 不加'g'，仅删除字符串里第一个"a"
        res.data.forEach(item=>{
          // console.log(item)
          departArr.push(item.departmentName)
          performanceArr.push(item.performance)
          proportionArr.push(Number(item.performanceEIncomeProportion.split('%')[0]))
          effectiveArr.push(item.effectiveIncome)
        })
        this.drawFour(departArr,performanceArr,proportionArr,effectiveArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 有效收入绩效关系分析
    drawFour(departArr,performanceArr,proportionArr,effectiveArr){
      // console.log(departArr,performanceArr,proportionArr,effectiveArr)
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('fourChat'))
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center',
          text: '有效收入绩效关系分析'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          formatter(params) {
            let str = "";
            str += `<div>
                  <p/><p>
                   ${ params[0].axisValue}：
                    </br>
                    ${
                params[0].seriesName+'：'+ params[0].value
            }
                  </br>
                   ${
                params[1].seriesName+'：'+ params[1].value
            }</br>
                   ${
                params[2].seriesName+'：'+ params[2].value+'%'
            }
                  </p>
                  </div>`;
            return str;
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        legend: {
          left:0
        },
        xAxis: [
          {
            type: 'category',
            data: departArr,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              // rotate: 45, // 旋转角度
              interval: 0 // 强制显示所有标签
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '有效收入',
            type: 'bar',
            label: {
              show: true,
              position: 'inside'
            },
            // itemStyle:{
            //   normal: {
            //     color: '#589cf4', //线的颜色
            //   },
            // },
            data: effectiveArr
          },
          {
            name: '绩效',
            type: 'bar',
            label: {
              show: true,
              position: 'inside'
            },
            // itemStyle:{
            //   normal: {
            //     color: '#ffab85', //线的颜色
            //   },
            // },
            data: performanceArr
          },
          {
            name: '占比',
            type: 'line',
            yAxisIndex: 1,
            label: {
              formatter: function(params) {
                return params.data +"%"
              },
              show: true,
              position: 'inside',
            },
            // lineStyle:{
            //   normal: {
            //     color: '#ff7d43', //线的颜色
            //   },
            //   color: "#ff7d43",// 小圆点的颜色
            // },
            data: proportionArr
          }
        ]
      });
    },
    // 收入、有效收入、绩效排名对比
    async incomeEffectiveIncomePerformanceRank(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.incomeEffectiveIncomePerformanceRank(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],textdataArr=[]
        res.data.forEach(item=>{
          departArr.push([item.departmentName])
          textdataArr.push([item.departmentName])
        })
        for(let i=0;i<res.data.length;i++){
          textdataArr.forEach((item)=>{
            if(item==res.data[i].departmentName){
              item.push(res.data[i].incomeRank,Number(res.data[i].effectiveIncomeRank),Number(res.data[i].performanceRank))
            }
          })
          departArr.forEach((item)=>{
            if(item==res.data[i].departmentName){
              item.push(Number(res.data[i].income),Number(res.data[i].effectiveIncome),Number(res.data[i].performance))
            }
          })
        }
        this.drawFive(departArr,textdataArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
//     收入、有效收入、绩效排名对比
    drawFive(departArr,textdataArr){
      // console.log(departArr,textdataArr)
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('fiveChat'))
      // 绘制图表
      myChart.setOption({
        legend: {
          left:0
        },
        title: {
          left: 'center',
          text: '收入、有效收入、绩效排名对比'
        },
        tooltip: {},
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        dataset: {
          source: departArr
        },
        xAxis: {
          type: 'category' ,
          axisLabel: {
            // rotate: 45, // 旋转角度
            interval: 0 // 强制显示所有标签
          }},
        yAxis: {},
        series: [
            { name: '收入排名',type: 'bar',label: {
                formatter: function(params) {
                    return textdataArr[params.dataIndex][1]
                  // return params.data[1]
                },
                show: true,
                position: 'inside',
              }, },
          { name: '有效收入排名',type: 'bar',label: {
              formatter: function(params) {
                // return params.data[2]
                  return textdataArr[params.dataIndex][2]
              },
              show: true,
              position: 'inside',
            }, },
          { name: '绩效排名',type: 'bar',label: {
              formatter: function(params) {
                // return params.data[3]
                  return textdataArr[params.dataIndex][3]
              },
              show: true,
              position: 'inside',
            },},]
      });
    },
    // 药占比与材料占比分析
    async materialDrugProportion(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.materialDrugProportion(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],materialArr=[],drugproArr=[]
        res.data.forEach(item=>{
          // console.log(item)
          departArr.push(item.departmentName)
          materialArr.push(item.materialProportion.split('%')[0])
          drugproArr.push(item.drugProportion.split('%')[0])
        })
        this.drawSix(departArr,materialArr,drugproArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
//     药占比与材料占比分析
    drawSix(departArr,materialArr,drugproArr){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('sixChat'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '药占比与材料占比分析',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = "";
            str += `<div>
                  <p/><p>
                   ${ params[0].axisValue}：
                    </br>
                    ${
                params[0].seriesName+'：'+ params[0].value+'%'
            }
                  </br>
                   ${
                params[1].seriesName+'：'+ params[1].value+'%'
            }
                  </p>
                  </div>`;
            return str;
          },
        },
        legend: {
          left:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: departArr,
          axisLabel: {
            // rotate: 45, // 旋转角度
            interval: 0 // 强制显示所有标签
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '材料占比',
            type: 'line',
            label: {
              formatter: function(params) {
                return params.data+'%'
              },
              show: true,
              position: 'top',
            },
            data: materialArr
          },
          {
            name: '药品占比',
            type: 'line',
            label: {
              formatter: function(params) {
                return params.data+'%'
              },
              show: true,
              position: 'top',
            },
            data: drugproArr
          }
        ]
      });
    },
    // 人均收入与排名分析
    async incomeAvgRankAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.incomeAvgRankAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],incomeArr=[]
        res.data.forEach(item=>{
          // console.log(item)
          departArr.push(item.departmentName)
          incomeArr.push(item.incomeAvg)
        })
        this.drawNine(departArr,incomeArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 人均收入与排名分析
    drawNine(departArr,incomeArr){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('nineChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center',
          text: '人均收入与排名分析'
        },
        tooltip: {
          trigger: "axis",// 鼠标指的时候显示文字
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          data: departArr,
          axisLabel: {
            // rotate: 45, // 旋转角度
            interval: 0 // 强制显示所有标签
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: incomeArr,
            type: 'bar',
            itemStyle: {    //柱状颜色和圆角
              color: '#1890ff',
            },
            label: {
              show: true,
              position: 'top',
            },
          }
        ]
      });
    },
//     人均有效收入与排名分析
    async effectiveIncomeAvgRankAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.effectiveIncomeAvgRankAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],effectiveArr=[]
        res.data.forEach(item=>{
          // console.log(item)
          departArr.push(item.departmentName)
          effectiveArr.push(item.effectiveIncomeAvg)
        })
        this.drawTen(departArr,effectiveArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 人均有效收入与排名分析
    drawTen(departArr,effectiveArr){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('tenChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center',
          text: '人均有效收入与排名分析'
        },
        tooltip: {
          trigger: "axis",// 鼠标指的时候显示文字
        },
        xAxis: {
          type: 'category',
          data: departArr,
          axisLabel: {
            // rotate: 45, // 旋转角度
            interval: 0 // 强制显示所有标签
          }
        },
        yAxis: {
          type: 'value'
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            data: effectiveArr,
            type: 'bar',
            itemStyle: {    //柱状颜色和圆角
              color: '#1890ff',
            },
            label: {
              show: true,
              position: 'top',
            },
          }
        ]
      });
    },
//     人均有效收入与绩效关系分析
    async effectiveAvgPerformanceAvgAnalyse(){
      let data={
        startDate:this.startTime,
        endDate:this.endTime,
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      }
      let { data: res } = await this.$axios.effectiveAvgPerformanceAvgAnalyse(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        let departArr=[],performanceArr=[],pofcomeavgArr=[],effectiveArr=[]
        res.data.forEach(item=>{
          // console.log(item)
          departArr.push(item.departmentName)
          effectiveArr.push(item.effectiveIncomeAvg)
          pofcomeavgArr.push(Number(item.personAvgProportion.split('%')[0]))
          performanceArr.push(item.performanceOfPAvg)
        })
        this.drawEleven(departArr,performanceArr,pofcomeavgArr,effectiveArr);
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
//     人均有效收入与绩效关系分析
    drawEleven(departArr,performanceArr,pofcomeavgArr,effectiveArr){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('elevenChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          left: 'center',
          text: '人均有效收入与绩效关系分析',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          formatter(params) {
            let str = "";
            str += `<div>
                  <p/><p>
                   ${ params[0].axisValue}：
                    </br>
                    ${
                params[0].seriesName+'：'+ params[0].value
            }
                  </br>
                   ${
                params[1].seriesName+'：'+ params[1].value
            }</br>
                   ${
                params[2].seriesName+'：'+ params[2].value+'%'
            }
                  </p>
                  </div>`;
            return str;
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        legend: {
          left:0
        },
        xAxis: [
          {
            type: 'category',
            data: departArr,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              // rotate: 45, // 旋转角度
              interval: 0 // 强制显示所有标签
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '人均有效收入',
            type: 'bar',
            label: {
              show: true,
              position: 'inside'
            },
            data: effectiveArr
          },
          {
            name: '人均绩效',
            type: 'bar',
            label: {
              show: true,
              position: 'inside'
            },
            data: performanceArr
          },
          {
            name: '人均绩效占人均有效收入比例',
            type: 'line',
            yAxisIndex: 1,
            label: {
              formatter: function(params) {
                return params.data +"%"
              },
              show: true,
              position: 'inside',
            },
            data: pofcomeavgArr
          }
        ]
      });
    },
},

};
</script>
<style lang="less" scoped>
.twoForm {
  float: right;
  display: flex;
}

.mainClas{
  display: flex;
  flex-direction: column;
}

// 取消开始时间和结束时间的×符号
/deep/ .twoForm .timClas .el-input__suffix{
  display: none!important;
}
</style>
